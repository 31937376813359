import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { State } from "../../store/types";
import Lottie from "react-lottie";
import QRScan from "../../assets/images/scan.png";
import DialogConfirm from "../../components/Dialog/dialogConfirm";
import DialogRedeemed from "../../components/Dialog/dialogRedeemed";
import DialogStatus from "../../components/Dialog/dialogStatus";
import _ from "lodash";
import {
  setTextBackSeed,
  setTextFrontSeed,
  setFRedeem,
  setOpenFront,
  setOpenBack
} from "../../store/paperWallet";
import { constantsRedeem } from "../../constants";
import { useTokenId } from "../../hooks/useTokenId";
import { adminService } from "../../services/adminServices";
import useRefresh from "../../hooks/useRefresh";
import loading from "../../uikit-dev/animations/loading.json";
import Error from "../../assets/logo/error-404.png";

const optionLoading = {
  loop: true,
  autoplay: true,
  animationData: loading
};

function Redeem() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { fastRefresh } = useRefresh();
  const [openScan, setOpenScan] = useState(false);
  const [dialogOn, setDialogOn] = useState(false);
  // eslint-disable-next-line
  const [dialogRedeemed, setDialogRedeemed] = useState(false);
  const [gift, setGift] = useState<string[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [eventComplete, setEventComplete] = useState<any[]>([]);
  const [confirmed, setConfirmed] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const listClaimItems = constantsRedeem.claimItems;
  const listEvents = constantsRedeem.eventBonus;
  const frontSeed = useSelector((state: State) => state.paperWallet.frontSeed);
  const backSeed = useSelector(
    (state: State) => state.paperWallet.backSeed
  ).split(":")[1];
  const ownerAddress = useSelector(
    (state: State) => state.paperWallet.ownerAddress
  );
  const [point, setPoint] = useState("");
  const [redeemed, setRedeemed] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const tokenID = useTokenId(ownerAddress || "");
  const [indexSelect, setIndexSelect] = useState(0);
  const [messageCode, setMessageCode] = useState("");
  const [dialogOnStatus, setDialogOnStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const getID = params.get("id") || "";
  // const regex = /^[0-9]+$/;
  // const match = regex.test(getID);
  const [nftImage, setNftImage] = useState("");

  useEffect(() => {
    let updatedListEvent: any[] = [...listEvents];
    let updatedList: any[] = [...listClaimItems];
    if (tokenID !== undefined) {
      const getPoint = async () => {
        await adminService
          .getMetaData(Number(tokenID))
          .then((res) => {
            const data = _.get(res, "data");
            const image = _.get(data, "image");
            setNftImage(image);
            const attributes = _.get(data, "attributes");
            // eslint-disable-next-line
            attributes.map((v) => {
              if (v.trait_type === "Points") {
                setPoint(v.value);
              }
              if (v.trait_type === "Redeemed") {
                if (v.value === "No") {
                  setRedeemed(false);
                  // eslint-disable-next-line
                  updatedList.map((obj, i) => {
                    updatedList[i].disabled = false;
                    updatedList[i].selected = false;
                  });
                } else {
                  setRedeemed(true);
                  // eslint-disable-next-line
                  updatedList.map((obj, i) => {
                    updatedList[i].disabled = true;
                    updatedList[i].selected = true;
                  });
                }
              }
              // eslint-disable-next-line
              updatedListEvent.map((r) => {
                setLoading(false);
                if (isEvent === false) {
                  if (v.trait_type === "Bonus 1" && v.trait_type === r.desc) {
                    if (v.value === "No") {
                      updatedListEvent[r.id - 1].selected = false;
                      updatedListEvent[r.id - 1].disabled = false;
                    } else {
                      updatedListEvent[r.id - 1].selected = true;
                      updatedListEvent[r.id - 1].disabled = true;
                    }
                  }

                  if (v.trait_type === "Bonus 2" && v.trait_type === r.desc) {
                    if (v.value === "No") {
                      updatedListEvent[r.id - 1].selected = false;
                      updatedListEvent[r.id - 1].disabled = false;
                    } else {
                      updatedListEvent[r.id - 1].selected = true;
                      updatedListEvent[r.id - 1].disabled = true;
                    }
                  }
                  if (
                    v.trait_type === "Checked In" &&
                    v.trait_type === r.desc
                  ) {
                    if (v.value === "No") {
                      updatedListEvent[r.id - 1].selected = false;
                      updatedListEvent[r.id - 1].disabled = false;
                    } else {
                      updatedListEvent[r.id - 1].selected = true;
                      updatedListEvent[r.id - 1].disabled = true;
                    }
                  }
                }
              });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      };
      getPoint();
    } else {
      if (frontSeed === "") {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [tokenID, fastRefresh, listEvents, listClaimItems]);

  const handleAction = async (item: any, i: any) => {
    let updatedList: any[] = [...listEvents];
    updatedList[i].loading = true;
    if (tokenID !== undefined) {
      await adminService
        .adminAction(
          frontSeed,
          backSeed,
          item.name,
          tokenID.toString(),
          getID || ""
        )
        .then((res) => {
          if (_.get(res, "status") === 200) {
            const transactionHash = _.get(res, "data.data.transactionHash");
            if (transactionHash !== undefined) {
              updatedList[i].selected = true;
              updatedList[i].loading = false;
              setEventComplete(item);
              setIsEvent(true);
            } else {
              const transactionHash = _.get(res, "data.statusCode");
              setMessageCode(constantsRedeem.STATUS_CODE[transactionHash].MSG);
              setDialogOnStatus(true);
              setStatus(true);
              updatedList[i].selected = false;
              updatedList[i].disabled = false;
              updatedList[i].loading = false;
            }
          } else {
            const transactionHash = _.get(res, "data.statusCode");
            setMessageCode(constantsRedeem.STATUS_CODE[transactionHash].MSG);
            setDialogOnStatus(true);
            setStatus(false);
            updatedList[i].selected = false;
            updatedList[i].disabled = false;
            updatedList[i].loading = false;
          }
        })
        .catch(() => {
          setMessageCode("Failed");
          setDialogOnStatus(true);
          setStatus(false);
          updatedList[i].selected = false;
          updatedList[i].disabled = false;
          updatedList[i].loading = false;
        });
    }
  };

  const handleDialog = async (item: any, i: any, isAis: boolean) => {
    let array: string[] = [];
    setSelected(item);
    setIsEvent(false);
    array.push(_.get(item, "desc"), item.imgs);
    await setGift(array);
    setDialogOn(true);
    setIndexSelect(i);
  };

  const onConfirm = async (flg: boolean) => {
    let updatedList: any[] = [...listClaimItems];
    if (tokenID !== undefined && flg) {
      updatedList[indexSelect].loading = true;
      await adminService
        .adminAction(
          frontSeed,
          backSeed,
          _.get(selected, "name"),
          tokenID.toString(),
          getID || ""
        )
        .then((res) => {
          if (_.get(res, "status") === 200) {
            const transactionHash = _.get(res, "data.data.transactionHash");
            if (transactionHash !== undefined) {
              // eslint-disable-next-line
              updatedList.map((v, i) => {
                updatedList[i].selected = true;
                updatedList[i].disabled = true;
                updatedList[i].loading = false;
              });
            } else {
              const transactionHash = _.get(res, "data.statusCode");
              setMessageCode(constantsRedeem.STATUS_CODE[transactionHash].MSG);
              setDialogOnStatus(true);
              setStatus(false);
              updatedList[indexSelect].selected = false;
              updatedList[indexSelect].disabled = false;
              updatedList[indexSelect].loading = false;
            }
          } else {
            const transactionHash = _.get(res, "data.statusCode");
            setMessageCode(constantsRedeem.STATUS_CODE[transactionHash].MSG);
            setDialogOnStatus(true);
            setStatus(false);
            updatedList[indexSelect].selected = false;
            updatedList[indexSelect].disabled = false;
            updatedList[indexSelect].loading = false;
          }
        })
        .catch(() => {
          setMessageCode("Failed");
          setDialogOnStatus(true);
          setStatus(false);
          updatedList[indexSelect].selected = false;
          updatedList[indexSelect].disabled = false;
          updatedList[indexSelect].loading = false;
        });
    }
    setConfirmed(flg);
  };

  const onCancel = (flg: boolean) => {
    setCancel(flg);
  };

  useEffect(() => {
    let updatedListEvent: any[] = [...listEvents];
    let objIndexEvent = updatedListEvent.findIndex(
      (obj) => obj.id === _.get(eventComplete, "id")
    );

    if (confirmed) {
      setConfirmed(false);
      setIsEvent(false);
    }
    if (cancel) {
      setCancel(false);
      setIsEvent(false);
    }

    if (isEvent && loading === false) {
      setIsEvent(false);
      updatedListEvent[objIndexEvent].disabled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, listEvents, cancel, isEvent, loading, eventComplete]);

  const scanPaperWallet = () => {
    dispatch(setFRedeem(true));
    dispatch(setTextBackSeed(""));
    dispatch(setTextFrontSeed(""));
    dispatch(setOpenFront(false));
    dispatch(setOpenBack(false));
    navigate(`/?id=${getID}`);
    window.location.reload();
  };

  const clearState = () => {
    setDialogOnStatus(false);
  };

  return (
    <div>
      {getID ? (
        <main
          className={`pt-24 pb-10 min-h-screen flex-1 flex flex-col items-center`}
        >
          <>
            {openScan ? (
              scanPaperWallet()
            ) : (
              <>
                <div className="flex self-end justify-end mr-8 lg:mr-20">
                  <img
                    src={QRScan}
                    alt="QRScan"
                    height={80}
                    width={80}
                    className="cursor-pointer"
                    onClick={() => setOpenScan(true)}
                  />
                </div>
                {point !== "" && (
                  <div className="flex items-center justify-between p-5 m-8 border-2 border-solid rounded-xl border-1">
                    <div className="mr-5">
                      <img src={nftImage} alt="logoShow" width={150} />
                    </div>
                    <div>
                      {loading ? (
                        <div className="flex items-center justify-end">
                          <Lottie
                            options={optionLoading}
                            width="20%"
                            style={{ margin: "unset" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="mb-10">
                            <p className="text-sm font-bold text-center text-white md:text-lg font-montserrat">
                              Whale Gate:
                            </p>
                            <p className="text-lg text-white font-montserrat font-semiblod">
                              #{tokenID}
                            </p>
                          </div>
                          <div>
                            <p className="text-2xl font-bold text-center text-white font-montserrat">
                              {point || 0}
                            </p>
                            <p className="text-lg text-center text-white font-montserrat font-semiblod">
                              Points
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* Events */}
                <div className="flex items-center self-center mx-10 my-5 sm:self-start md:self-start">
                  <span className="text-lg font-bold text-white md:text-xl sm:text-xl font-montserrat">
                    Events Bonus
                  </span>
                </div>
                <div
                  className={`grid grid-cols-2 sm:grid-cols-4 gap-6 mb-10 mx-10`}
                >
                  {listEvents?.map((item: any, index: number) =>
                    loading ? (
                      <div
                        key={item.id}
                        className="flex items-center justify-center bg-gray-500 rounded-lg bg-opacity-30"
                      >
                        <Lottie
                          options={optionLoading}
                          height={120}
                          width={200}
                          style={{ margin: "unset" }}
                        />
                      </div>
                    ) : (
                      <div className="relative" key={item.id}>
                        {item.loading && (
                          <div className="absolute flex items-center justify-center">
                            <Lottie
                              options={optionLoading}
                              height={100}
                              width={140}
                              style={{ margin: "unset" }}
                            />
                          </div>
                        )}
                        {item.selected && (
                          <p className="absolute font-semibold text-white transform -rotate-45 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 font-montserrat">
                            Completed
                          </p>
                        )}

                        <div
                          className={`${
                            frontSeed === ""
                              ? "cursor-pointer"
                              : (item.disabled === false ||
                                  item.loading === false) &&
                                "cursor-pointer"
                          } rounded-xl hover:bg-blue-100 ${
                            frontSeed === ""
                              ? "pointer-events-none opacity-30"
                              : (item.disabled || item.loading) &&
                                "pointer-events-none opacity-30"
                          }`}
                          key={index}
                          onClick={() => handleAction(item, index)}
                        >
                          <img src={item.imgs} alt="list-event" width={200} />
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="flex items-center self-center mx-10 mb-5 sm:self-start md:self-start">
                  <span className="text-lg font-bold text-white md:text-xl sm:text-xl font-montserrat">
                    Claim
                  </span>
                </div>
                <div
                  className={`grid grid-cols-2 sm:grid-cols-4 gap-6 mb-8 mx-10`}
                >
                  {listClaimItems?.map(
                    (item, index) =>
                      loading ? (
                        <div
                          key={item.id}
                          className="flex items-center justify-center bg-gray-500 rounded-lg bg-opacity-20"
                        >
                          <Lottie
                            options={optionLoading}
                            height={120}
                            width={200}
                            style={{ margin: "unset" }}
                          />
                        </div>
                      ) : (
                        <div key={item.id} className="relative">
                          {item.loading && (
                            <div className="absolute flex items-center justify-center">
                              <Lottie
                                options={optionLoading}
                                height={100}
                                width={140}
                                style={{ margin: "unset" }}
                              />
                            </div>
                          )}
                          <div
                            className={`${
                              frontSeed === "" ||
                              Number(point) <= 0 ||
                              Number(point) < Number(item.points)
                                ? "cursor-pointer"
                                : (item.disabled || item.loading) === false &&
                                  "cursor-pointer"
                            } rounded-xl ${
                              frontSeed === "" ||
                              Number(point) <= 0 ||
                              Number(point) < Number(item.points)
                                ? "pointer-events-none opacity-30"
                                : (item.disabled || item.loading) &&
                                  "pointer-events-none opacity-30"
                            }`}
                            onClick={() => handleDialog(item, index, false)}
                          >
                            <img src={item.imgs} alt="list-gift" width={200} />
                          </div>
                        </div>
                      )
                    // </div>
                  )}
                </div>

                {dialogOn && (
                  <DialogConfirm
                    setDialogOn={setDialogOn}
                    item={gift}
                    confirm={onConfirm}
                    cancel={onCancel}
                  />
                )}
                {dialogOnStatus && (
                  <DialogStatus
                    setDialogOnStatus={setDialogOnStatus}
                    message={messageCode}
                    status={status}
                    clearState={clearState}
                  />
                )}
                {redeemed && (
                  <DialogRedeemed
                    goToScan={scanPaperWallet}
                    setDialogRedeemed={setDialogRedeemed}
                  />
                )}
              </>
            )}
          </>
        </main>
      ) : (
        <div>
          <main className={`h-100 d-flex justify-content-center`}>
            <div className="flex items-center justify-center min-h-screen">
              <div>
                <img
                  src={Error}
                  alt="Error"
                  width={300}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </main>
        </div>
      )}
    </div>

    // </>
  );
}

export default Redeem;
